html, body, #root {
    /* background-color: #282c34; */
    color: #fff;
    height: 100%;
}

.md-form {
    margin-bottom: 0 !important;
}

.card {
    font-weight: 300 !important;
}

::placeholder {
    color: #c0c0c0 !important;
}

:-ms-input-placeholder {
    color: #c0c0c0;
}

 ::-ms-input-placeholder {
    color: #c0c0c0;
}

#toolbar {
    background-color: #212121;
}

#logo-icon {
    height: 30px;
    margin-top: -12px;
}

#card-contents h1 {
    font-size: 20pt;
}

#card-contents h2 {
    font-size: 18pt;
}

#card-contents h3 {
    font-size: 16pt;
}

#card-contents h4 {
    font-size: 14pt;
}

#card-contents h2.category {
    position: absolute;
    top: -35px;
    left: 0;
    white-space: nowrap;
    color: #fff;
    width: 100%;
    overflow: hidden;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: unset !important;
    border-radius: .25rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}